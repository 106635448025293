
import ClipboardJS from 'clipboard';
import { defineAsyncComponent, defineComponent } from 'vue';

export default defineComponent({
  name: 'qqBrowser',
  data() {
    return {
      href: window.location.href,
      toast: {
        visible: false,
        message: '',
      },
    };
  },
  components: {
    Toast: defineAsyncComponent(() => import('@/components/toast/index.vue')),
  },
  methods: {
    handleCopy() {
      const clipboard = new ClipboardJS('.copy-btn');
      const that = this; /* eslint-disable-line */
      clipboard.on('success', (e) => {
        console.log(e);
        that.toast = {
          visible: true,
          message: '复制成功',
        };
        e.clearSelection();
      });

      clipboard.on('error', (e) => {
        console.error(e);
        that.toast = {
          visible: true,
          message: '复制成功',
        };
      });
    },
  },
});
